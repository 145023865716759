/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Container } from 'reactstrap';
import { Link, navigate } from 'gatsby';
import Stock001 from '../images/assets/stock001.png';
import Stock002 from '../images/assets/stock002.png';
import Staff001 from '../images/assets/staff001.png';
import Truck001 from '../images/assets/truck001.png';
import Truck002 from '../images/assets/truck002.png';
import ImgLogo from '../images/logoLNext-edit-old-2nd.png';
import '../components/publicLayout.css';
import { getHistoryNotification } from '../redux/actions/driver/notifications';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PaginationComponent from '../components/Pagination';
import ItemPerPage from '../views/util/components/FilterTotalItemNotification';

const ChangeEmail = (props: any) => {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state: any) => state.authCustomer.accessToken, shallowEqual,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const [listNotification, setListNotification] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await dispatch(getHistoryNotification(accessToken, limit, offset, props.role === 'member' ? 'driver' : props.role));
      setListNotification(data.result);
      setTotalPage(data.total_page);
      setCurrentPage(data.page);
      setTotalCount(data.total_count);
      setOffset(data.offset);
    })();
  }, [accessToken, dispatch]);

  const callbackFunction = (e) => {
    (async () => {
      const data = await dispatch(getHistoryNotification(accessToken, limit, e, props.role === 'member' ? 'driver' : props.role));
      setListNotification(data.result);
      setTotalPage(data.total_page);
      setCurrentPage(data.page);
      setTotalCount(data.total_count);
      setOffset(data.offset);
    })();
  };
  const onChangeItemPerPage = (e) => {
    setLimit(e);
    (async () => {
      const data = await dispatch(getHistoryNotification(accessToken, e, offset, props.role === 'member' ? 'driver' : props.role));
      setListNotification(data.result);
      setTotalPage(data.total_page);
      setCurrentPage(data.page);
      setTotalCount(data.total_count);
      setOffset(data.offset);
    })();
  };
  return (
    <>
      <div className="wrapper">
        <Link to="/customer">
          TOPに戻る
          <span style={{ color: '#e00010', fontWeight: 600 }}>{' ＞'}</span>
        </Link>
        <div className="main-notifications">
          <Container>
            <div style={{ fontSize: '40px', textAlign: 'center' }} className="l-next-logo-n">
              <img src={ImgLogo} alt="logo" className="logo-header-e" />
            </div>
            <div style={{ paddingLeft: '10%', paddingBottom: 10 }}>
              <ItemPerPage
                totalPage={totalPage}
                limit={limit}
                parentCallback={onChangeItemPerPage}
              />
            </div>

            {
               listNotification?.length > 0 && listNotification?.map((item : any) => (
                 <>
                   <div className="hover-detail" style={{ display: 'flex', justifyContent: 'center' }} onClick={() => navigate(`/notification/${item.id}`)}>
                     <div style={{ width: '80%' }}>
                       <div className="title-notification">
                         <p style={{ fontWeight: 'bold' }}>
                           {item.posted_date?.substring(0, 10)?.replace(/-/g, '.')}
                         </p>
                         <p className="title-notifications"> お知らせ</p>
                         <b style={{ marginLeft: 10 }}>{item.title}</b>
                       </div>
                     </div>
                   </div>
                 </>
               ))
            }
            {
                totalPage > 0 && (
                <div className="paging">
                  <PaginationComponent
                    itemsPerPage={totalCount}
                    totalPage={totalPage}
                    limit={limit}
                    offset={offset}
                    page={currentPage}
                    parentCallback={callbackFunction}
                  />
                </div>
                )
            }

          </Container>
        </div>

        <div className="bg">
          <img src={Stock001} className="stock001" alt="Stock001" />
          <img src={Stock002} className="stock002" alt="Stock002" />
          <img src={Staff001} className="staff001" alt="Staff001" />
          <img src={Truck001} className="truck001" alt="Truck001" />
          <img src={Truck002} className="truck002" alt="Truck002" />
        </div>
      </div>
    </>
  );
};

export default ChangeEmail;
