import React, { useState } from 'react';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { FiChevronDown } from 'react-icons/all';

const ItemPerPage = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [lastClicked, setLastClicked] = useState(props.limit);
  const isDisplay = !!props.totalPage;
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const onChangePerPage = (value) => {
    setLastClicked(value);
    props.parentCallback(value);
  };
  return (
    <>
      <div style={{ textAlign: 'left', display: 'flex' }}>
        <p style={{ marginTop: 10, paddingRight: 10 }}>表示件数</p>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle style={{ background: 'none', color: 'black', width: '5rem' }}>
            {lastClicked}
            <FiChevronDown className="ms-2" />
          </DropdownToggle>
          <DropdownMenu style={{ width: 'fit-content' }}>
            <DropdownItem onClick={() => onChangePerPage(5)}>5件</DropdownItem>
            <DropdownItem onClick={() => onChangePerPage(10)}>10件</DropdownItem>
            <DropdownItem onClick={() => onChangePerPage(20)}>20件</DropdownItem>
            <DropdownItem onClick={() => onChangePerPage(50)}>50件</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

export default ItemPerPage;
