import * as CONST from '../../ActionTypes';
import * as util from '../index';

/**
 * 掲示板
 */
export const getListGetNotification = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/bulletin_boards?limit=${param.limit}&offset=${param.offset}&sort=created_at.desc&search=${param.search}&role=${param.role}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.actionType(CONST.GET_LIST_NOTIFICATIONS_SUCCESS, payload));
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_NOTIFICATIONS_FAILURE, error));
      dispatch(util.unLoading());
    });
};

export const getNotificationDetail: any = (accessToken, id) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/bulletin_boards/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      //dispatch(util.actionType(CONST.GET_DETAIL_NOTIFICATIONS_SUCCESS, payload));
      dispatch(util.unLoading());
      return payload;
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.GET_LIST_NOTIFICATIONS_FAILURE, error));
      dispatch(util.unLoading());
      return [];
    });
};

export const postReply = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/bulletin_boards/${param.bulletin_board_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.POST_REPLY_SUCCESS, null));
        dispatch(getNotificationDetail(accessToken, param.bulletin_board_id));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.POST_REPLY_FAILURE, null));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.POST_REPLY_FAILURE, error));
      dispatch(util.unLoading());
    });
};

export const addNotification = (accessToken, param) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/bulletin_boards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(param),
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.requestSuccess(CONST.ADD_NOTIFICATION_SUCCESS, null));
        return null;
      }
      return response.json();
    })
    .then((payload) => {
      if (payload) {
        dispatch(util.requestFailure(CONST.ADD_NOTIFICATION_FAILURE, null));
      }
      dispatch(util.unLoading());
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.ADD_NOTIFICATION_FAILURE, error));
      dispatch(util.unLoading());
    });
};
/**
 * 顧客一覧取得
 */
export const getNotificationById = (accessToken, id) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/public/bulletin_board_top/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.unLoading());
      dispatch(util.requestFailure(CONST.GET_DETAIL_NOTIFICATIONS_TOP_SUCCESS, payload));
      return payload;
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.GET_LIST_NOTIFICATIONS_TOP_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 顧客一覧取得
 */
export const getHistoryNotification :any = (accessToken, limit, offset, role) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/public/bulletin_board_top/history?limit=${limit}&offset=${offset}&sort=created_at.desc&role=${role}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => response.json())
    .then((payload) => {
      dispatch(util.unLoading());
      return payload;
    })
    .catch((error) => {
      dispatch(util.requestFailure(CONST.GET_LIST_NOTIFICATIONS_TOP_FAILURE, error));
      dispatch(util.unLoading());
      return [];
    });
};

export const removeBulletinBoard = (accessToken, bulletin_board_id) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/bulletin_boards/${bulletin_board_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.actionType(CONST.REMOVE_BULLETIN_BOARD_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.REMOVE_BULLETIN_BOARD_FAILURE, error));
      dispatch(util.unLoading());
    });
};

export const removeBulletinBoardRely = (
  accessToken,
  bulletin_board_id,
  bulletin_board_rely_id,
) => (dispatch) => {
  dispatch(util.loading());
  return fetch(`${process.env.REACT_APP_ENDPOINT}/api/v1/drivers/bulletin_board_rely/${bulletin_board_id}/${bulletin_board_rely_id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(util.actionType(CONST.REMOVE_BULLETIN_BOARD_RELY_SUCCESS, null));
        dispatch(util.unLoading());
        return null;
      }
      return response.json();
    })
    .catch((error) => {
      dispatch(util.actionType(CONST.REMOVE_BULLETIN_BOARD_RELY_FAILURE, error));
      dispatch(util.unLoading());
    });
};

/**
 * 明確なリストの通知
 */
export const clearListNotifications = () => (dispatch) => {
  dispatch(util.actionType(CONST.CLEAR_LIST_NOTIFICATIONS, null));
};
